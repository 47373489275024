import st from './Textarea.module.scss';


type TextareaType = {

    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
    name?: string;
    ico?: 'clock' | 'blank';
    style?: any;

}

function Textarea({placeholder = '', name, value, onChange, ico, style}: TextareaType) {
    return (<>
        <textarea style={style} className={st.textarea + ' ' + ((ico === 'clock') ? st.textarea_withIco : '')}
                  placeholder={placeholder}
                  onChange={onChange} name={name} value={value}/>
        </>
    )
        ;
}

export default Textarea