import React, {memo, useEffect} from "react";
import st from "../../styles/template.module.scss";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import {useImmer} from "use-immer";

function PriceOptionsLine({obj, onChangeLine, value=''}: any) {

    useEffect(()=>{
        if (value!==''){
            setReadOnly(false);
        }
    },[]);
    const change = (obj: HTMLInputElement, code: string) => {
        if (obj.checked) {
            setReadOnly(false);
            onChangeLine(code, '');
        } else {
            setReadOnly(true);
            onChangeLine(code, '---');
        }
    }
    //включение и выключение input полей
    const [readOnly = true, setReadOnly] = useImmer(true);
    return (
        <>
            <div className={st.formVertical}>
                <div className={st.formHorizontal}>
                    <Checkbox isChecked={!readOnly} name={obj.code + '_check'} onChange={(e) => change(e.target, obj.code)}/> {obj.name}
                </div>
                <Input name={obj.code} value={readOnly ? '' : value} isReadOnly={readOnly}
                       onChange={(e) => onChangeLine(e.target.name, e.target.value)}
                       ico={obj.name=='Deposit'?'clock':'blank'}
                       hasError={!readOnly && value === ''}/>
            </div>
        </>);
}

export default memo(PriceOptionsLine);